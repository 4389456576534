import { render, staticRenderFns } from "./certificateList.vue?vue&type=template&id=962cb634&scoped=true&"
import script from "./certificateList.vue?vue&type=script&lang=js&"
export * from "./certificateList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "962cb634",
  null
  
)

export default component.exports